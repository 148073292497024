import React, {JSX, useEffect, useState} from "react";
import {Link, NavigateFunction, useNavigate} from "react-router-dom";
import {LinkButton} from "../../component/Button";
import {elemType, LoginType, loginType, loginTypeInfo, MenuType, mainMenu} from "../../info/Info";
import {JNode, Nodes} from "../../utils/Nodes";
import "../../assets/scss/Header.scss";

export default function Header(info: {loginType: LoginType}): JSX.Element {
    const [mainMenus,  setMainMenus ] = useState<MenuType[]>(mainMenu);
    const [loginTypes, setLoginTypes] = useState<MenuType[]>([]);
    const navigate   : NavigateFunction = useNavigate();

    let loginTypeTemp: MenuType[] = [];
    let mainMenuNode : Nodes<HTMLElement> | undefined;
    let mainInfoNode : Nodes<HTMLElement> | undefined;
    let homeMenuNode : Nodes<HTMLElement> | undefined;

    useEffect((): void => {
        loginTypeInfo.forEach((type): void => {
            if (info.loginType == type.type) {
                type.content.forEach((content, idx): void => {
                    const type: MenuType = {
                        id  : idx,
                        name: content.name,
                        path: content.path
                    };

                    loginTypeTemp?.push(type);
                });
            }
        });
        setLoginTypes(loginTypes.concat(loginTypeTemp));
    }, []);

    useEffect((): void => {
        mainMenuNode = JNode<HTMLElement>('#main-nav > div.top-nav > ul.menu > li');
        mainInfoNode = JNode<HTMLElement>('#main-nav > div.top-nav > ul.info > li');
        homeMenuNode = JNode<HTMLElement>('#main-nav > div.top-nav > p.home');
        console.log(homeMenuNode);
    }, [navigate]);

    const MainMenuClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
        const currentNode: EventTarget & HTMLElement = e.currentTarget;

        console.log(homeMenuNode);
        homeMenuNode?.hasClass('on') && homeMenuNode?.removeClass('on');

        mainMenuNode?.each((node: Nodes<HTMLElement>): void => {
            node.hasClass('on') && node.removeClass('on');
        });

        mainMenus.forEach((menu: MenuType): void => {
            //currentNode.innerText == menu.name && navigate(menu.path, {replace: false});
        });

        currentNode.classList.add('on');
    }

    const HomeMenuClick = (): void => {
        mainMenuNode?.each((node: Nodes<HTMLElement>): void => {
            node.hasClass('on') && node.removeClass('on');
        });

        console.log(homeMenuNode);
        homeMenuNode?.addClass('on');
        //navigate('/home', {replace: false});
    }

    return (
        <div id={"header"}>
            <div className={"top"}>
                <div id={"main-nav"}>
                    <div className={"top-nav"}>
                        <p  className={"home on"} onClick={HomeMenuClick}></p>
                        <ul className={"menu"}>
                            {
                                mainMenus.map(menu => (
                                    <LinkButton key={menu.id} name={menu.name} onClick={MainMenuClick} type={elemType.li} />
                                ))
                            }
                        </ul>
                        <ul className={"info"}>
                            {
                                loginTypes.map(menu => (
                                    <LinkButton key={menu.id} name={menu.name} path={menu.path} type={elemType.li} />
                                ))
                            }
                        </ul>
                    </div>
                    <div className={"bottom-nav"}>
                        <ul className={"menu"}>

                        </ul>
                        <ul className={"info"}>

                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
}