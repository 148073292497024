
interface positionInfo {
    left?  : number;
    top?   : number;
    width? : number;
    height?: number;
}

interface scrollOption {
    left?    : number;
    top?     : number;
    behavior?: ScrollBehavior;
}

abstract class _Node<T extends HTMLElement> {
    private readonly _list?: NodeListOf<T> | null;
    private readonly _node?: T  | null;
    private _nodes?: Nodes<T>[] | null | undefined = [];

    protected constructor(selector: string | T | T[] | Document | HTMLCollection, context?: HTMLElement) {
        if (typeof selector == 'string') {
            this._node = NodeInfo.find <T>(selector, context);
            this._list = NodeInfo.finds<T>(selector, context);

            if (this._list.length > 0) {
                this._list.forEach((node: T): void => {
                    let n: Nodes<T> | undefined = JNode<T>(node);
                    if (n) this._nodes?.push(n);
                });
            }
        }
        else if (selector instanceof HTMLCollection) {
            this._node = selector.item(0) as T;

            if (selector.length > 0) {
                for (let i: number=0; i<selector.length; ++i) {
                    let n: Nodes<T> | undefined = JNode<T>(selector.item(i) as T);
                    if (n) this._nodes?.push(n);
                }
            }
        }
        else if (selector instanceof Document) {
            // @ts-ignore
            this._node = selector.documentElement;
        }
        else if (Array.isArray(selector)) {
            this._node = selector[0];

            if (selector.length > 0) {
                selector.forEach((node: T): void => {
                    let n: Nodes<T> | undefined = JNode<T>(node);
                    if (n) this._nodes?.push(n);
                });
            }
        }
        else {
            this._node = selector;
        }
    }

    public get getNodes(): Nodes<T>[]    | null | undefined {
        return this._nodes;
    }

    public get getList() : NodeListOf<T> | null | undefined {
        return this._list;
    }

    public get getFind() : T             | null | undefined {
        return this._node;
    }
}

class NodeInfo {
    public static find <T extends HTMLElement>(selector: string, context?: HTMLElement): T | null {
        let nodeTemp: T | null;

        if (context) {
            nodeTemp = context.querySelector(selector);
        }
        else {
            nodeTemp = document.querySelector(selector);
        }

        return nodeTemp;
    }

    public static finds<T extends HTMLElement>(selector: string, context?: HTMLElement): NodeListOf<T> {
        let nodeTemp: NodeListOf<T>;

        if (context) {
            nodeTemp = context.querySelectorAll(selector);
        }
        else {
            nodeTemp = document.querySelectorAll(selector);
        }

        return nodeTemp;
    }

    /** By */
    public static byId  <T extends HTMLElement>(selector: string): T | null {
        return document.getElementById(selector) as T;
    }

    public static byName<T extends HTMLElement>(selector: string): NodeListOf<T> {
        return document.getElementsByName(selector) as NodeListOf<T>;
    }

    public static byClass  (selector: string, context?: HTMLElement): HTMLCollectionOf<Element> {
        let nodeTemp: HTMLCollectionOf<Element>;

        if (context) {
            nodeTemp = context.getElementsByClassName(selector);
        }
        else {
            nodeTemp = document.getElementsByClassName(selector);
        }

        return nodeTemp;
    }

    public static byTagName(selector: string, context?: HTMLElement): HTMLCollectionOf<Element> {
        let nodeTemp: HTMLCollectionOf<Element>;

        if (context) {
            nodeTemp = context.getElementsByTagName(selector);
        }
        else {
            nodeTemp = document.getElementsByTagName(selector);
        }

        return nodeTemp;
    }

    /** Css */
    public static getCss     <T extends HTMLElement>(element: T, type: string, pseudoElt?: string): string {
        let style: CSSStyleDeclaration = getComputedStyle(element, pseudoElt);
        return style.getPropertyValue(type);
    }

    public static setCss     <T extends HTMLElement>(element: T, type: string, value: string, priority?: string): void {
        element.style.setProperty(type, value, priority);
    }

    public static removeCss  <T extends HTMLElement>(element: T, type: string): void {
        element.style.removeProperty(type);
    }

    /** Class */
    public static addClass   <T extends HTMLElement>(element: T | NodeListOf<T>, name: string): void {
        if (element instanceof HTMLElement) {
            element.classList.add(name);
        }
        else {
            element.forEach((node: T): void => {
                node.classList.add(name);
            });
        }
    }

    public static hasClass   <T extends HTMLElement>(element: T, name: string): boolean {
        return element.classList.contains(name);
    }

    public static toggleClass<T extends HTMLElement>(element: T | NodeListOf<T>, name: string): void {
        if (element instanceof HTMLElement) {
            element.classList.toggle(name);
        }
        else {
            element.forEach((node: T): void => {
                node.classList.toggle(name);
            });
        }
    }

    public static removeClass<T extends HTMLElement>(element: T | NodeListOf<T>, name: string): void {
        if (element instanceof HTMLElement) {
            element.classList.remove(name);
        }
        else {
            element.forEach((node: T): void => {
                node.classList.remove(name);
            });
        }
    }

    /** Info */
    public static text<T extends HTMLElement>(node: T | string): string;
    public static text<T extends HTMLElement>(node: T | string, str : string): void;
    public static text<T extends HTMLElement>(node: T | string, str?: string): string | void {
        let find: T | null;
        if (typeof node == 'string') {
            find = this.find(node);
        }
        else {
            find = node;
        }

        if (str == undefined) {
            return (find) ? find.innerText : '';
        }
        else {
            if (find) find.innerText = str;
        }
    }

    public static html<T extends HTMLElement>(node: T | string): string;
    public static html<T extends HTMLElement>(node: T | string, str : string): void;
    public static html<T extends HTMLElement>(node: T | string, str?: string): string | void {
        let find: T | null;
        if (typeof node == 'string') {
            find = this.find(node);
        }
        else {
            find = node;
        }

        if (str == undefined) {
            return (find) ? find.innerHTML : '';
        }
        else {
            if (find) find.innerHTML = str;
        }
    }

    public static textContent<T extends HTMLElement>(node: T | string): string;
    public static textContent<T extends HTMLElement>(node: T | string, str : string): void;
    public static textContent<T extends HTMLElement>(node: T | string, str?: string): string | void {
        let find: T | null;
        if (typeof node == 'string') {
            find = this.find(node);
        }
        else {
            find = node;
        }

        if (str == undefined) {
            return (find && find.textContent) ? find.textContent : '';
        }
        else {
            if (find) find.textContent = str;
        }
    }

    /** Event */
    public static on  <T extends HTMLElement>(node: T | string, event: string, callback: (e: Event)=>void): void {
        let find: T | null;
        if (typeof node == 'string') {
            find = this.find(node);
        }
        else {
            find = node;
        }

        if (find) {
            find.addEventListener(event, callback, {passive: true});
        }
    }

    public static once<T extends HTMLElement>(node: T | string, event: string, callback: (e: Event)=>void): void {
        let find: T | null;
        if (typeof node == 'string') {
            find = this.find(node);
        }
        else {
            find = node;
        }

        if (find) {
            find.addEventListener(event, callback, {once: true});
        }
    }

    public static off <T extends HTMLElement>(node: T | string, event: string, callback: (e: Event)=>void): void {
        let find: T | null;
        if (typeof node == 'string') {
            find = this.find(node);
        }
        else {
            find = node;
        }

        if (find) {
            find.removeEventListener(event, callback!);
        }
    }
}

class Nodes<T extends HTMLElement> extends _Node<T> {
    private _eventListener: any | null = null;

    constructor(selector: string | T | T[] | Document | HTMLCollection, context?: HTMLElement) {
        super(selector, context);
    }

    public get length(): number {
        if (this.getNodes) {
            return this.getNodes.length;
        }
        return 0;
    }

    public focus(): void {
        if (this.getFind) {
            this.getFind.focus();
        }
    }

    public blur (): void {
        if (this.getFind) {
            this.getFind.blur();
        }
    }

    /** Css */
    public css(type: string): string;
    public css(type: string, value : string, option?: string): Nodes<T>;
    public css(type: string, value?: string, option?: string): Nodes<T> | string |undefined {
        if (this.getFind) {
            if (value == undefined) {
                return NodeInfo.getCss<T>(this.getFind, type, option);
            }
            else {
                NodeInfo.setCss<T>(this.getFind, type, value, option);
                return this;
            }
        }
        else {
            if (value == undefined) {
                return '';
            }
        }
    }

    public removeCss  (type: string): void    {
        if (this.getFind)
            NodeInfo.removeCss<T>(this.getFind, type);
    }

    /** Class */
    public addClass   (name: string): void    {
        if (this.getFind)
            NodeInfo.addClass<T>(this.getFind, name);
    }

    public hasClass   (name: string): boolean {
        if (this.getFind)
            return NodeInfo.hasClass<T>(this.getFind, name);
        else
            return false;
    }

    public toggleClass(name: string): void    {
        if (this.getFind)
            NodeInfo.toggleClass<T>(this.getFind, name);
    }

    public removeClass(name: string): void    {
        if (this.getFind)
            NodeInfo.removeClass<T>(this.getFind, name);
    }

    /** Find */
    public each(callback: (node: Nodes<T>, idx: number)=>void): void {
        if (this.getNodes) {
            this.getNodes.forEach((node: Nodes<T>, idx: number): void => {
                callback(node, idx);
            });
        }
    }

    public eq(idx: number): Nodes<T> {
        return this.getNodes![idx];
    }

    public children<U extends HTMLElement>(name?: string): Nodes<U> | undefined {
        if (this.getFind) {
            if (name != undefined) {
                let searchNode: NodeListOf<U> = this.getFind.querySelectorAll(name);
                let searchSet : U[]           = [];

                for (let i: number=0; i<this.getFind.children.length; ++i) {
                    searchNode.forEach((n: U): void => {
                        if (n == this.getFind?.children.item(i) as U) {
                            searchSet.push(n);
                        }
                    });
                }

                let node: Nodes<U> | undefined = JNode<U>(searchSet);
                if (node) return node;
            }
            else {
                let node: Nodes<U> | undefined = JNode<U>(this.getFind.children);
                if (node) return node;
            }
        }
        return undefined;
    }

    public node    <U extends HTMLElement>(idx: number): U {
        return this.getList!.item(idx) as unknown as U;
    }

    public find    <U extends HTMLElement>(name: string): Nodes<U> | null {
        if (this.getFind) {
            let n: Nodes<U> | undefined = JNode<U>(name, this.getFind);
            if (n) return n;
        }
        return null;
    }

    public parent  <U extends HTMLElement>(): Nodes<U> | null {
        if (this.getFind) {
            let pt: U | null = this.getFind.parentElement as U;
            if (pt   != null) {
                let n: Nodes<U> | undefined = JNode<U>(pt);
                if (n) return n;
            }
        }

        return null;
    }

    /** Add */
    public before  (node: HTMLElement | string): void {
        if (this.getFind) {
            this.getFind.before(node);
        }
    }

    public after   (node: HTMLElement | string): void {
        if (this.getFind) {
            this.getFind.after(node);
        }
    }

    public append  (node: HTMLElement | string): void {
        if (this.getFind) {
            this.getFind.append(node);
        }
    }

    public prepend (node: HTMLElement | string): void {
        if (this.getFind) {
            this.getFind.prepend(node);
        }
    }

    public addChild<T extends HTMLElement>(node: T): void {
        if (this.getFind) {
            this.getFind.appendChild<T>(node);
        }
    }

    public insertHTML(position: InsertPosition, text: string): void {
        if (this.getFind) {
            this.getFind.insertAdjacentHTML(position, text);
        }
    }

    public removeChild<T extends HTMLElement>(node: T): void {
        if (this.getFind) {
            this.getFind.removeChild<T>(node);
        }
    }

    /** Info */
    public val ()   : string;
    public val (str : string): void;
    public val (str?: string): void | string {
        if (this.getFind &&
            (
                this.getFind instanceof HTMLInputElement  ||
                this.getFind instanceof HTMLSelectElement ||
                this.getFind instanceof HTMLOptionElement ||
                this.getFind instanceof HTMLTextAreaElement
            )
        ) {
            if (str == undefined) {
                return this.getFind.value;
            }
            else {
                this.getFind.value = str;
            }
        }
    }

    public text()   : string;
    public text(str : string): void;
    public text(str?: string): string | void {
        if (this.getFind == undefined) return;
        if (str == undefined) {
            return NodeInfo.text<T>(this.getFind);
        }
        else {
            NodeInfo.text<T>(this.getFind, str);
        }
    }

    public html()   : string;
    public html(str : string): void;
    public html(str?: string): string | void {
        if (this.getFind == undefined) return;
        if (str == undefined) {
            return NodeInfo.html<T>(this.getFind);
        }
        else {
            NodeInfo.html<T>(this.getFind, str);
        }
    }

    public textContent()   : string | null;
    public textContent(str : string): void;
    public textContent(str?: string): string | void | null {
        if (this.getFind == undefined) return;
        if (str == undefined) {
            return NodeInfo.textContent<T>(this.getFind);
        }
        else {
            NodeInfo.textContent<T>(this.getFind, str);
        }
    }

    /** Change */
    public is(name: string): boolean {
        if (this.getFind) {
            // @ts-ignore
            return this.getFind[name];
        }
        return false;
    }

    public attr(name: string): string;
    public attr(name: string, val : string): T | null
    public attr(name: string, val?: string): string | null | T {
        if (this.getFind) {
            if (val == undefined) {
                if (this.getFind.hasAttribute(name)) {
                    return this.getFind.getAttribute(name);
                }
                else {
                    return null;
                }
            }
            else {
                this.getFind.setAttribute(name, val);
                return this.getFind;
            }
        }
        else {
            return null;
        }
    }
    public removeAttr(name: string): void {
        if (this.getFind) {
            this.getFind.removeAttribute(name);
        }
    }

    public prop(name: string): string | number | boolean;
    public prop(name: string, val : string | number | boolean): void;
    public prop(name: string, val?: string | number | boolean): string | number | boolean | void {
        if (this.getFind) {
            if (val == undefined) {
                // @ts-ignore
                return this.getFind[name];
            }
            else {
                // @ts-ignore
                this.getFind[name] = val;
            }
        }
    }

    public data(name: string): string;
    public data(name: string, val : string): void;
    public data(name: string, val?: string): string | void {
        if (this.getFind) {
            if (val == undefined) {
                return this.getFind.dataset[name];
            }
            else {
                return this.getFind.dataset[name] = val;
            }
        }
    }

    /** Position */
    public position()   : positionInfo;
    public position(set : positionInfo): void;
    public position(set?: positionInfo): void | positionInfo {
        if (this.getFind) {
            if (set == undefined) {
                return {left: this.getFind.offsetLeft, top: this.getFind.offsetTop}
            }
            else {
                if (set.left != undefined) this.getFind.style.left = set.left + 'px';
                if (set.top  != undefined) this.getFind.style.top  = set.top  + 'px';
            }
        }
    }

    public client(): positionInfo {
        if (this.getFind) {
            return {
                left  : this.getFind.clientLeft,
                top   : this.getFind.clientTop,
                width : this.getFind.clientWidth,
                height: this.getFind.clientHeight
            }
        }
        else {
            return {}
        }
    }

    public offset(): positionInfo {
        if (this.getFind) {
            return {
                left  : this.getFind.offsetLeft,
                top   : this.getFind.offsetTop,
                width : this.getFind.offsetWidth,
                height: this.getFind.offsetHeight
            }
        }
        else {
            return {}
        }
    }

    public left() : number;
    public left(l : number | string): void;
    public left(l?: number | string): void | number {
        if (this.getFind) {
            if (l == undefined) {
                return this.getFind.offsetLeft;
            }
            else {
                this.getFind.style.left = (typeof l == 'number') ? l + 'px' : l;
            }
        }
    }

    public top() : number;
    public top(t : number | string) : void;
    public top(t?: number | string) : void | number {
        if (this.getFind) {
            if (t == undefined) {
                return this.getFind.offsetTop;
            }
            else {
                this.getFind.style.top = (typeof t == 'number') ? t + 'px' : t;
            }
        }
    }

    public width(): number;
    public width(w : number | string): void;
    public width(w?: number | string): void | number {
        if (this.getFind) {
            if (w == undefined) {
                return Number(this.getFind.style.width.replace(/\s|px|%/ig, ''));
            }
            else {
                this.getFind.style.width = (typeof w == 'number') ? w + 'px' : w;
            }
        }
    }

    public height(): number;
    public height(h : number): void;
    public height(h?: number): void | number {
        if (this.getFind) {
            if (h == undefined) {
                return Number(this.getFind.style.height.replace(/\s|px|%/ig, ''));
            }
            else {
                this.getFind.style.width = h + 'px';
            }
        }
    }

    public inner(): positionInfo | undefined {
        if (this.getFind) {
            return {
                width : this.getFind.clientWidth,
                height: this.getFind.clientHeight,
            }
        }
    }
    public innerWidth (): number | undefined {
        if (this.getFind) {
            return this.getFind.clientWidth
        }
    }
    public innerHeight(): number | undefined {
        if (this.getFind) {
            return this.getFind.clientHeight
        }
    }

    public outer(): positionInfo | undefined {
        if (this.getFind) {
            return {
                width : this.getFind.offsetWidth,
                height: this.getFind.offsetHeight,
            }
        }
    }
    public outerWidth (): number | undefined {
        if (this.getFind) {
            return this.getFind.offsetWidth
        }
    }
    public outerHeight(): number | undefined {
        if (this.getFind) {
            return this.getFind.offsetHeight
        }
    }

    public vSet(): DOMRect | undefined {
        if (this.getFind) {
            return this.getFind.getBoundingClientRect();
        }
    }

    public dSet(): positionInfo | undefined {
        if (this.getFind) {
            return {
                left: window.scrollX + this.getFind.getBoundingClientRect().left,
                top : window.scrollY + this.getFind.getBoundingClientRect().top
            }
        }
    }

    public scrollOffSet(): positionInfo {
        if (this.getFind) {
            return {
                left  : this.getFind.scrollLeft,
                top   : this.getFind.scrollTop,
                width : this.getFind.scrollWidth,
                height: this.getFind.scrollHeight
            }
        }
        else {
            return {}
        }
    }

    public scrollTo(x: number, y: number): void;
    // @ts-ignore
    public scrollTo(option: scrollOption): void;
    public scrollTo(x?: number, y?: number, option?: scrollOption): void {
        if (this.getFind) {
            if (x != undefined && y != undefined) {
                this.getFind.scrollTo(x, y);
            }
            else {
                this.getFind.scrollTo(option);
            }
        }
    }

    public scrollBy(x: number, y: number): void;
    // @ts-ignore
    public scrollBy(option: scrollOption): void;
    public scrollBy(x?: number, y?: number, option?: scrollOption): void {
        if (this.getFind) {
            if (x != undefined && y != undefined) {
                this.getFind.scrollBy(x, y);
            }
            else {
                this.getFind.scrollBy(option);
            }
        }
    }

    /** Event */
    public on  (event: string, callback: (e: Event)=>void): void {
        this.addEvent(event, callback);
    }

    public once(event: string, callback: (e: Event)=>void): void {
        this.addEvent(event, callback, true);
    }

    public off (event: string, callback?:(e: Event)=>void): void {
        if (this.getFind) {
            if (callback != undefined) {
                NodeInfo.off<T>(this.getFind, event, callback);
                delete this._eventListener[event];
            }
            else {
                if (this._eventListener[event]) {
                    NodeInfo.off<T>(this.getFind, this._eventListener[event].event, this._eventListener[event].callback);
                    delete this._eventListener[event];
                }
            }
        }
    }

    private addEvent(event: string, callback: (e: Event)=>void, once: boolean=false): void {
        if (this.getFind) {
            if (this._eventListener        == undefined)   this._eventListener = {};
            if (this._eventListener[event] == undefined || this._eventListener[event] == null) {
                this._eventListener[event] = {event, callback};

                if (once)
                    NodeInfo.once<T>(this.getFind, event, callback);
                else
                    NodeInfo.on  <T>(this.getFind, event, callback);
            }
            else {
                this.off(this._eventListener[event].event, this._eventListener[event].callback);
                this.addEvent(event, callback, once);
            }
        }
    }
}

let JNode = function<T extends HTMLElement>(selector: string | T | T[] | Document | HTMLCollection, context?: HTMLElement): Nodes<T> | undefined {
    let nodes: Nodes<T> = new Nodes<T>(selector, context);

    if (nodes.getFind) {
        return nodes;
    }
    else {
        return undefined;
    }
}

export {JNode, NodeInfo, Nodes};