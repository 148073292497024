import React, {JSX} from 'react'
import {BrowserRouter, Routes, Route} from 'react-router-dom'

/* Layout */
import MainLayout from "../layout/MainLayout";

/* Page */
import {Home} from "../pages/Home";
import NotFound from "../pages/NotFound";
import OnlineHome from "../pages/onlin/OnlinHome";
import OfflineHome from "../pages/offlin/OfflineHome";
import BoardView from "../pages/board/BoardView";

function CommonRouter(): JSX.Element {
    return (
        <BrowserRouter>
            <Routes>
                {/* Main */}
                <Route element={<MainLayout />}>
                    <Route path="/"           element={<Home />}></Route>
                    <Route path="/home"       element={<Home />}></Route>
                    <Route path="/online"     element={<OnlineHome />}></Route>
                    <Route path="/offline"    element={<OfflineHome />}></Route>
                    <Route path="/board-view" element={<BoardView />}></Route>
                </Route>

                {/* 404 ERROR */}
                <Route path="*" element={<NotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default CommonRouter;