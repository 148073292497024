import React, {JSX} from "react";
import {Link} from "react-router-dom";
import {elemType, ElemType} from "../info/Info";

interface EType {
    info: LinkInfo;
    val : JSX.Element;
}

interface LinkInfo {
    name    : string;
    path?   : string;
    type?   : ElemType;
    onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
}

function CreateElem(info: EType): JSX.Element {
    const linkInfo: LinkInfo = info.info;

    if (     linkInfo.type === elemType.p) {
        return (linkInfo.onClick) ? <p      onClick={linkInfo.onClick}>{info.val}</p>      : <p     >{info.val}</p>;
    }
    else if (linkInfo.type === elemType.li) {
        return (linkInfo.onClick) ? <li     onClick={linkInfo.onClick}>{info.val}</li>     : <li    >{ info.val}</li>;
    }
    else if (linkInfo.type === elemType.div) {
        return (linkInfo.onClick) ? <div    onClick={linkInfo.onClick}>{info.val}</div>    : <div   >{info.val}</div>;
    }
    else if (linkInfo.type === elemType.button) {
        return (linkInfo.onClick) ? <button onClick={linkInfo.onClick}>{info.val}</button> : <button>{info.val}</button>;
    }
    else {
        return <></>;
    }
}

function LinkButton(info: LinkInfo): JSX.Element {
    const val: JSX.Element = info.path !== undefined ? <Link to={info.path}>{info.name}</Link> : <>{info.name}</>;
    return (
        <CreateElem info={info} val={val} />
    );
}

export {LinkButton};