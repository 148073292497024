import {createGlobalStyle} from "styled-components";


const GlobalStyles = createGlobalStyle`
  html, body {
    width : 100%;
    height: 100%;
  }
  
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin         : 0;
    padding        : 0;
    border         : 0;
    font-size      : 100%;
    vertical-align : baseline;
    box-sizing     : border-box;
    font-family    : 'Noto Sans KR', 'Dotum', sans-serif;
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  input, select, button {box-sizing : border-box;}
  input:focus  {outline:none;}
  select:focus {outline:none;}
  body {
    line-height: 0;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  em {
    font-style: normal;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a.no-uline {
    text-decoration: none;
  }
  a.noul:hover {
    text-decoration: none !important;
  }
  a:link {
    text-decoration: none;
  }
  a:visited {
    text-decoration: none;
  }
  a:hover {
    text-decoration: none !important;
  }

  .clear {
    width  : 0;
    height : 0;
    margin : 0;
    padding: 0;
    border : none;
    clear  : both;
  }
  .clearfix {*zoom:1;}
  .clearfix:before, .clearfix:after {display:block; content: ''; line-height: 0;}
  .clearfix:after {clear: both;}

  .pointer { cursor : pointer; }
`;

export default GlobalStyles;