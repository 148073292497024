export interface MenuType {
    id  : number,
    name: string,
    path: string
}

export const mainMenu = [
    {
        id  : 1,
        name: '강의',
        path: '/online'
    },
    {
        id  : 2,
        name: '제품',
        path: '/offline'
    },
    {
        id  : 3,
        name: '고객센터',
        path: '/board-view'
    }
];


export const loginType = {
    normal : 'normal',
    user   : 'user',
    manager: 'manager'
} as const;
export type LoginType = typeof loginType[keyof typeof loginType];

export const loginTypeInfo= [
    {
        type: 'normal',
        content: [
            {
                name: '로그인',
                path: '/'
            },
            {
                name: '회원가입',
                path: '/'
            }
        ]
    },
    {
        type: 'user',
        content: [
            {
                name: '마이페이지',
                path: '/'
            },
            {
                name: '로그아웃',
                path: '/'
            }
        ]
    },
    {
        type: 'manager',
        content: [
            {
                name: '관리자페이지',
                path: '/'
            },
            {
                name: '로그아웃',
                path: '/'
            }
        ]
    }
];


export const elemType   = {
    p     : 'p',
    li    : 'li',
    div   : 'div',
    button: 'button'
} as const;
export type ElemType = typeof elemType[keyof typeof elemType];