import React, {JSX} from "react";
import Header from "../pages/common/Header";
import Footer from "../pages/common/Footer";
import {Outlet} from "react-router-dom";
import {LoginType, loginType} from "../info/Info";

function MainLayout(): JSX.Element {
    const loginTypeInfo: LoginType = loginType.normal;

    return (
        <>
            <Header loginType={loginTypeInfo}></Header>
            <Outlet />
            <Footer></Footer>
        </>
    );
}

export default MainLayout;