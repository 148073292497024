import React, {JSX} from 'react';
import './App.css';
import {ThemeProvider} from "styled-components";
import GlobalStyles from "./GlobalStyles";
import CommonRouter from "./router/CommonRouter";

const theme = {
    backgroundColor: '#000'
}

function App(): JSX.Element {
  return (
      <React.Fragment>
          <ThemeProvider theme={theme}>
              <GlobalStyles />
              <CommonRouter />
          </ThemeProvider>
      </React.Fragment>
  );
}

export default App;
